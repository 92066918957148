* {
  font-family: 'Noto Sans KR', sans-serif;
}

h1 {
  background-image: linear-gradient(to right, #262364, #A82B69);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 42px;
}

h3 {
  background-image: linear-gradient(to right, #262364, #A82B69);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 18px;
}

.centered-text {
  text-align: center;
}

.bold-text {
  font-weight: bold;
}

.red-border {
  border: 2px solid red !important;
}